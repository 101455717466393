<template>
  <div class="roleManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='roleManagement'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="角色管理" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
              <el-form-item label="角色名称">
                <el-select v-model="formInline.roleId" placeholder="选择状态" filterable clearable>
                  <el-option
                    v-for="item in roleNameData"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色状态">
                <el-select v-model="formInline.lockFlag" placeholder="选择状态" filterable clearable>
                  <el-option label="正常" value="ENABLE"></el-option>
                  <el-option label="禁用" value="DISABLE"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-date-picker
                  @change="timeEvent"
                  v-model="timeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item>
                <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
                <el-button type="info" plain @click="resetEvent">重置</el-button>
              </el-form-item> -->
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="versionEvent('add')" plain v-allow="'sys_role_add'">+添加角色</el-button>
            <el-button class="mainBtn" @click="batchOperaEvent('ENABLE')" plain v-allow="'sys_role_batch'">批量启用</el-button>
            <el-button class="mainBtn" @click="batchOperaEvent('DISABLE')" plain v-allow="'sys_role_batch'">批量禁用</el-button>
            <el-button class="mainBtn" @click="batchDelete" plain v-allow="'sys_role_del'">批量删除</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
            row-key='roleId'
            ref="table"
            @selection-change="handleSelectionChange">
            <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="55">
            </el-table-column>
            <el-table-column
              prop="roleName"
              label="角色名称"
              align="center">
            </el-table-column>
            <el-table-column
              prop="userCount"
              label="用户数"
              align="center">
              <template slot-scope="scope">
                <span @click="watchRoleListEvent(scope.row)" style="color:#1282FF;cursor:pointer;">{{scope.row.userCount}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="remarks"
              label="角色备注"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.remarks" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.remarks}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="lockFlag"
              label="状态"
              align="center"
              width="180">
              <template slot-scope="scope">
                {{scope.row.lockFlag == 'ENABLE' ? '启用' : '禁用'}}
                <el-switch
                  v-allow="'sys_role_batch'"
                  v-model="scope.row.lockFlag"
                  active-value="ENABLE"
                  inactive-value="DISABLE"
                  @change="chageRoleStatus(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime, 'datetime')}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="180"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="versionEvent('edit',scope.row)" v-allow="'sys_role_edit'">编辑</el-button>
                <el-button type="text" @click="jumpAuthority(scope.row)" v-allow="'sys_role_manage'">权限管理</el-button>
                <el-button @click="deleteEvent(scope.row)" type="text" v-allow="'sys_role_del'">删除</el-button>
                <!-- <el-popover
                  placement="bottom"
                  width="240"
                  trigger="hover">
                  <div class="pop_area">
                    <el-button type="text" @click="versionEvent('edit',scope.row)">编辑</el-button>
                    <el-button type="text" @click="jumpAuthority(scope.row)">权限管理</el-button>
                    <el-button type="text" @click="watchRoleListEvent(scope.row)">查看用户</el-button>
                    <el-button @click="deleteEvent(scope.row)" type="text">删除</el-button>
                  </div>
                  <el-button type="text" slot="reference">操作选择···</el-button>
                </el-popover> -->
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>

    <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="">
        <p>{{deleteType == 1 ? '确定要删除该角色吗？' : deleteType == 2 ? '确定要批量删除角色吗？' :deleteType == 3 ? '确定要批量启用角色吗？' : '确定要批量禁用角色吗？'}}</p>
        <div class="btn_area">
          <el-button @click="batchDelete">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>

    <!-- 用户列表 -->
    <dialogDiy :isShow="isShowRoleList" :dialogDiyData="dialogRoleListData" @dialogEvent="dialogRoleListEvent">
      <div slot="dialogContent" class="dia_opera_role">
        <el-table
          :data="roleListData"
          max-height="360"
          border
          style="width: 100%">
          <el-table-column
            prop="username"
            label="用户账号"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="realName"
            label="用户名称"
            show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
    </dialogDiy>

    <el-dialog class="compile" :title="!isType ? '新增角色' : '编辑角色'" :visible.sync="isShowVer" @close='closeEvent'>
      <el-form :rules="rules" :model="form" label-width="100px" ref="form">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" placeholder="角色名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="角色备注">
          <el-input v-model="form.remarks" placeholder="角色备注" maxlength="25" clearable></el-input>
        </el-form-item>
        <el-form-item label="角色状态">
          <el-select v-model="form.lockFlag" placeholder="请选择角色状态" clearable>
            <el-option label="启用" value="ENABLE"></el-option>
            <el-option label="禁用" value="DISABLE"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowVer = false">取 消</el-button>
        <el-button type="primary" @click="enterEvent('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import publicChildren from '../publicChildren'
import Pagination from '../Pagination'
import dialogDiy from '../commonModule/dialogDiy'
import way from '../../api/encapsulation'
export default {
  name: 'roleManagement',
  components: {
    publicChildren,
    Pagination,
    dialogDiy,
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        createTimeEnd: '',
        createTimeStart: '',
        lockFlag: null,
        page: 1,
        pageSize: 10,
        roleId: ''
      },
      tableData: [],
      roleListData: [], // 用户列表数据
      roleNameData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      timeVal: '',
      idArr: [],
      moduleData: [],
      isShow: false,
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
      isShowRoleList: false,
      dialogRoleListData: {
        title: '用户列表',
        sizeStyle: {width: '450px',height: '500px'}
      },
      deleteType: 1, // 1 单个删除， 2 批量删除
      isShowVer: false,
      form: {
        roleName: null,
        remarks: null,
        lockFlag: 'ENABLE'
      },
      isType: false,
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'roleManagement') {
          this.getRoleTableEvent();
          this.getRoleDownEvent();
        }
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 获取登录日志表格数据
    getRoleTableEvent() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getRoleTable(obj, res => {
        this.loading = false;
        if(res.code == 0){
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    // 获取角色名称数据
    getRoleDownEvent() {
      this.$request.getRoleDown({}, res=>{
        if(res.code == 0) {
          this.roleNameData = res.data;
        }
      })
    },
    timeEvent(val) {
      if(!val) {
        this.formInline.createTimeStart = '';
        this.formInline.createTimeEnd = '';
      }
    },
    batchOperaEvent(type) {
      this.deleteType = type == 'ENABLE' ? 3 : 4;
      this.isShow = !this.isShow;
    },
    handleSelectionChange(val) {
      let idArr = [];
      if(val.length > 0){
        idArr = val.map((item, index) => {
          return item.roleId
        })
      } else {
        idArr = val;
      }
      this.idArr = idArr;
    },
    chageRoleStatus(row) {
      let obj = {
        id: row.roleId
      }
      if(row.lockFlag == 'DISABLE') {
        this.$request.disableRole(obj).then(res => {
          if(res.data.code == 0){
            this.Success(res.data.msg)
          }
        }).catch(err => {
          row.lockFlag = 'ENABLE'
        })
      } else {
        this.$request.enableRole(obj).then(res=>{
          if(res.data.code == 0) {
            this.Success(res.data.msg)
          }
        }).catch(err => {
          row.lockFlag = 'DISABLE'
        })
      }
      
    },
    // 重置
    resetEvent() {
      this.formInline.status = '';
      this.formInline.lockFlag = '';
      this.formInline.createTimeStart = '';
      this.formInline.createTimeEnd = '';
      this.timeVal = '';
    },
    onSubmit() {
      if(this.timeVal) {
        this.formInline.createTimeStart = way.dateReturnTimestamp(this.timeVal[0]);
        this.formInline.createTimeEnd = way.dateReturnTimestamp(this.timeVal[1]);
      }
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getRoleTableEvent();
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getRoleTableEvent();
    },
    // 批量删除
    batchDelete() {
      this.deleteType = 2;
      this.isShow = !this.isShow;
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
    },
    dialogRoleListEvent(val) {
      this.isShowRoleList = !this.isShowRoleList;
    },
    watchRoleListEvent(row) {
      this.isShowRoleList = !this.isShowRoleList;
      this.$request.getRolePages({roleId: row.roleId}, res=>{
        if(res.code == 0) {
          this.roleListData = res.data;
        }
      })
    },
    // 删除
    deleteEvent(row) {
      this.deleteType = 1;
      this.rowStatus = row;
      this.isShow = !this.isShow;
    },
    doEvent() {
      if(this.deleteType == 1) {
        this.onlyDelete();
      } else if(this.deleteType == 2) {
        this.moreDelete();
      } else if(this.deleteType == 3) {
        this.batchEnable();
      } else {
        this.batchDisable();
      }
    },
    // 批量启用
    batchEnable() {
      if(this.idArr.length == 0) {
        this.Warn('请选择数据');
        return;
      }
      let ids = this.idArr;
      this.$request.enableRoles({ids}).then(res=>{
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.page = 1;
          this.getRoleTableEvent();
        }
      })
    },
    // 批量禁用
    batchDisable() {
      if(this.idArr.length == 0) {
        this.Warn('请选择数据');
        return;
      }
      let ids = this.idArr;
      this.$request.disableRoles({ids}).then(res=>{
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.page = 1;
          this.getRoleTableEvent();
        }
      })
    },
    // 单个删除执行事件
    onlyDelete() {
      let id = this.rowStatus.roleId;
      this.$request.deleteByRoleId({id}, res => {
        if(res.code == 0) {
          this.isShow = !this.isShow;
          this.rowStatus = '';
          this.Success(res.msg);
          this.formInline.page = 1;
          this.getRoleTableEvent();
        }
      })
    },
    // 批量删除执行事件
    moreDelete() {
      if(this.idArr.length == 0) {
        this.Warn('未选择需批量删除的数据');
        return;
      }
      let ids = this.idArr;
      this.$request.deleteByRoleIdList({ids}).then( res => {
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.page = 1;
          this.getRoleTableEvent();
        }
      })
    },
    versionEvent(type, row = {}) {
      this.isShowVer = !this.isShowVer;
      this.isType = type == 'edit' ? true : false;
      if(type != 'add') {
        this.editData(row)
      }
    },
    editData(row) {
      this.form.roleName = row.roleName;
      this.form.remarks = row.remarks;
      this.form.lockFlag = row.lockFlag;
      this.rowStatus = row;
    },
    enterEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(!this.isType) {
            this.addRoleEvent();
          } else {
            this.editRoleEvent();
          }
        }
      })
    },
    addRoleEvent() {
      let dto = this.form;
      this.$request.addRoleList(dto, res => {
        if(res.code == 0) {
            this.Success(res.msg);
            this.isShowVer = false;
            this.clearEvent();
            this.getRoleTableEvent();
        }
      })
    },
    editRoleEvent() {
      let dto = JSON.parse(JSON.stringify(this.form));
      dto.roleId = this.rowStatus.roleId;
      this.$request.updateRole(dto).then(res => {
        if(res.data.code == 0) {
            this.Success(res.data.msg);
            this.isShowVer = false;
            this.rowStatus = '';
            this.clearEvent();
            this.getRoleTableEvent();
        }
      })
    },
    closeEvent() {
      this.isShowVer = false;
      this.isType = false;
      this.clearEvent();
      this.resetForm('form');
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // clear
    clearEvent() {
      this.form.lockFlag = 'ENABLE';
      this.form.remarks = null;
      this.form.roleName = null;
    },
    // 权限管理
    jumpAuthority(row) {
      this.$router.push({name: 'authorityManagement', query: {
        id: row.roleId
      }})
    }
  },
}
</script>

<style lang="less">
  .roleManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .dia_opera_role {
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
    }
    .compile {
      .el-dialog {
        width: 400px;
      }
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .pop_area {
    text-align: center;
  }
  .pubToop {
    max-width: 600px;
  }
</style>